.footerOpen {
  bottom: 0;
  animation: move-up 0.3s;
}

.footerClose {
  bottom: -100%;
  height: 0;
  animation: move-down 0.1s;
}

@keyframes move-up {
  from {
    bottom: -100%;
  }
  to {
    bottom: 0; /* final resting position */
  }
}

@keyframes move-down {
  from {
    bottom: 0;
    height: auto;
  }
  to {
    bottom: -100%; /* final resting position */
    height: 0;
  }
}

@screen d {
  .footerOpen {
    bottom: auto;
    height: auto;
    animation: none;
  }

  .footerClose {
    bottom: auto;
    height: auto;
    animation: none;
  }
}

@screen desktop {
  .footerOpen {
    bottom: auto;
    height: auto;
    animation: none;
  }

  .footerClose {
    bottom: auto;
    height: auto;
    animation: none;
  }
}

