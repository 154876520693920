.rpSpinner {
  opacity: 1;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px;
  z-index: 1060;
  overflow-x: hidden;
  height: auto;
  background-color: rgba(255, 255, 255, 0.6);
  visibility: visible;
  -webkit-transition: opacity 600ms, visibility 600ms;
  transition: opacity 600ms, visibility 600ms;
}
.rpSpinner .lottie {
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: auto;
  width: 225px;
  height: 225px;
}

@media only screen and (max-width: 850px) {
  .rpSpinner .lottie {
    width: 170px;
    height: 170px;
  }
}
