@tailwind base;
@tailwind components;
@tailwind utilities;

@import '../components/common/ReactSelectSearch/ReactSelectSearch.css';
@import '../components/common/ReactDatePicker/ReactDatePicker.css';
@import '../components/common/ReactDatePickerLandingPage/ReactDatePicker.css';
@import '../components/common/SwiperCarousel/SwiperCarousel.css';
@import '../components/common/SmartCalendar/SmartCalendar.css';
@import '../components/common/Dropdown/Dropdown.css';
@import '../components/common/Popup/Popup.css';
@import '../components/common/SideScrollList/SideScrollList.css';
@import '../components/ShareItinerary/MultiEmail.css';
@import '../components/common/ImageGallery/ImageGallery.css';
@import '../components/common/SmartCalendar/MobileSmartCalendar/SmartCalendarMobileVariant.css';
@import '../components/HomePageVideo/HomePageVideo.css';

@layer base {
  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  :root {
    --color-primary: #57bfce;
    --color-primary-new: #193153;
    --color-primary-10: rgba(87, 191, 206, 0.1);
    --color-primary-16: rgba(87, 191, 206, 0.16);
    --color-primary-22: rgba(87, 191, 206, 0.22);
    --color-primary-light: #61c9d8;
    --color-primary-light-2: #00c2d1;
    --color-primary-dark: #02a6b5;
    --color-primary-dark-2: #38c0cd1a;
    --color-primary-light-3: #2c9eb5;
    --color-primary-light-4: #258d9c;
    --color-primary-light-5: #108e9d;
    --color-navy: #193153;
    --color-primary-black: #252525;
    --color-primary-black-light: #494749;
    --color-primary-black-medium: #333333;
    --color-primary-black-faded: rgba(37, 37, 37, 0.7);
    --color-primary-black-faded-50: rgba(37, 37, 37, 0.5);
    --color-primary-sky-blue: #f3f9fc;
    --color-primary-sky-blue-2: #deeff7;
    --color-surface-brand-daylight: #fef5f1;
    --color-surface-alert-warning: #feefd0;
    --color-secondary-blue: rgba(0, 194, 209, 0.2);
    --color-secondary-dark-blue: #d9f9fc;
    --color-secondary-green: #a6cd98;
    --color-secondary-red: #ff585b;
    --color-secondary-red-faded: rgba(255, 88, 91, 0.1);
    --color-secondary-red-faded-16: rgba(255, 88, 91, 0.16);
    --color-secondary-red-faded-22: rgba(255, 88, 91, 0.22);
    --color-hot-spot: #ff552f;
    --color-secondary-orange: #f8b600;
    --color-secondary-orange-dark: #ff781f;
    --color-secondary-orange-light: #d5861f;
    --color-secondary-orange-warning: #f2ebe3;
    --color-secondary-sunset-gradient: linear-gradient(135deg, #cc603f 0%, #f7a717 100%);
    --color-sky-blue-gradient: linear-gradient(180deg, #f6fafd00 0%, #f6fafd 66.15%);
    --color-sky-blue-gradient-2: linear-gradient(180deg, #fff 0%, #f7f7f7 100%);
    --color-sky-blue-gradient-3: linear-gradient(258.77deg, #deeff7 0%, #f3f9fc 99.59%);
    --color-secondary-sky-blue-gradient: linear-gradient(360deg, #ffffff 0%, #f3f9fc 100%);
    --color-giftcard-gradient: radial-gradient(
      157.44% 157.44% at 83.89% -50.19%,
      #a2e9a3 0%,
      #63bdcb 100%
    );
    --color-dark-gradient: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
    --color-lighter-water: #ccf3f6;
    --color-lighter-alice-blue: #f3f9fc;
    --color-lighter-ghost-white: #f6f8fa;
    --color-gray: #808080;
    --color-gray-2: #a0a0a0;
    --color-dark-gray: #717171;
    --color-dark-gray-2: #636366;
    --color-dark-gray-3: #919191;
    --color-light-gray: #fafafa;
    --color-light-gray-2: #f7f7f7;
    --color-light-gray-3: #f8fbfd;
    --color-light-gray-4: #f2f2f2;
    --color-light-gray-5: #e7ecef;
    --color-gray-divider: #ebebeb;
    --color-gray-border: #dbdbdb;
    --color-gray-border2: #dddddd;
    --color-gray-border3: #7f7f7f;
    --color-form-input-border: #929292;
    --color-success-text: #009a3f;
    --color-green: #61e0ba;
    --color-green-2: #2ecc71;
    --color-green-3: rgba(46, 204, 113, 0.1);
    --color-green-4: #06a449;
    --color-green-5: #118888;
    --color-green-6: #1ece6a;
    --color-gold: #ebac00;
    --color-gold-2: #946409;
    --color-gold-light: #fff7e0;
    --color-gold-star: #f4b73f;
    --color-red: #f14f4f;
    --color-red-2: #ee5253;
    --color-red-3: #7c1f20;
    --color-light-red: #f6d5d5;
    --color-light-red-2: #c78686;
    --color-coach: #1fa2b8d9;
    --color-black-half-faded: rgba(0, 0, 0, 0.5);
    --color-black-faded: rgba(0, 0, 0, 0.7);
    --color-black-faded-50: rgba(0, 0, 0, 0.5);
    --color-white-faded: rgba(255, 255, 255, 0.87);
    --color-white-faded-light: rgba(255, 255, 255, 0.5);
    --color-coupon-button: #eef9fa;
    --color-filter-active: #eef9f8;
    --color-pink: #faeeef;
    --color-dark-border: #b0b0b0;
    --color-blur: linear-gradient(0deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
    --color-yellow-highlighted: #fdfaee;
    --color-yellow-border: #e7c436;
    --color-admin-dark: #26383b;
    --color-date-selected: #f5f5f5;
    --color-active-input: #91b4f5;
    --color-light-background: #f6f8f9;
    --color-disabled: #6e8387;
    --color-warning: #d5861f;
    --color-danger: #d22030;
    --color-danger-2: #d32f2f;
    --color-toast-warning: #f2ebe3;
    --color-danger-3: #ffeeef;
    --color-credit-card-gradient: url('../assets/images/credit-card-background.png');
    --color-share-booking-card: url('../assets/images/share-booking-card.png');
    --color-water: #cdecf0;
    --color-blanched-almond: #fbeccd;
    --color-daylight: #fff5f0;
    --color-daylight-opacity: rgba(255, 245, 240, 0.5);
    --neutral-900: #22272a;
    --rp-primary: #3164e9;
    --rp-inverted: #fefefe;
    --rp-cloud: #f1f5fe;
    --rp-neutral: #22272a;
    --rp-shadow: #05102e;
    --rp-primary-hover: #2246a3;
    --rp-primary-pressed: #183274;
    --rp-inverted-hover: #e6ecfc;
    --rp-inverted-pressed: #b9cdff;
    --rp-text-secondary: #717688;
    --color-green-badge: #c8f0b6;
    --color-green-badge-text: #275b0d;
    --color-blue-badge: #c6ebe6;
    --color-blue-badge-text: #096e7a;
    --color-orange-badge: #fae8b4;
    --color-orange-badge-text: #c64d28;
    --color-text-disabled: #aeb1ba;
    --color-surface-alert-error: #ffdbdc;
    --color-border-red: #e12f31;
    --color-button-disabled: #eeeff2;
    --color-ultramarine: #3164e9;
  }

  @font-face {
    font-family: 'cera-bold';
    src: url('../assets/fonts/cera-gr/ceragrbold.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'cera-medium';
    src: url('../assets/fonts/cera-gr/ceragrmedium.woff2') format('woff2'),
      url('../assets/fonts/cera-gr/ceragrmedium.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'cera-regular';
    src: url('../assets/fonts/cera-gr/ceragrregular.woff2') format('woff2'),
      url('../assets/fonts/cera-gr/ceragrregular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'cera-thin';
    src: url('../assets/fonts/cera-gr/ceragrthin.woff2') format('woff2'),
      url('../assets/fonts/cera-gr/ceragrthin.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'proxima-nova-semi-bold';
    src: url('../assets/fonts/proxima-nova/proximanova-semibold-webfont.woff2') format('woff2'),
      url('../assets/fonts/proxima-nova/proximanova-semibold-webfont.woff') format('woff'),
      url('../assets/fonts/proxima-nova/proximanova-semibold-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'proxima-nova-regular';
    src: url('../assets/fonts/proxima-nova/proximanova-regular-webfont.woff2') format('woff2'),
      url('../assets/fonts/proxima-nova/proximanova-regular-webfont.woff') format('woff'),
      url('../assets/fonts/proxima-nova/proximanova-regular-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'proxima-nova-light';
    src: url('../assets/fonts/proxima-nova/proximanova-light-webfont.woff2') format('woff2'),
      url('../assets/fonts/proxima-nova/proximanova-light-webfont.woff') format('woff'),
      url('../assets/fonts/proxima-nova/proximanova-light-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'gelica-light';
    src: url('../assets/fonts/gelica/gelica-light.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'gelica-light-italic';
    src: url('../assets/fonts/gelica/gelica-lightitalic.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'basetica';
    src: url('../assets/fonts/basetica/Basetica-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'basetica-bold';
    src: url('../assets/fonts/basetica/Basetica-Bold.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'basetica-semibold';
    src: url('../assets/fonts/basetica/Basetica-Medium.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'basetica-light';
    src: url('../assets/fonts/basetica/Basetica-Light.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
}

@layer utilities {
  @variants responsive {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }

    .no-scroll {
      overflow: hidden;
    }
  }
}

html,
body {
  width: 100%;
  position: relative;
  /* touch-action: none; */
}

.dlp-common a {
  /* color: #02a6b5; */
  text-decoration: underline;
  font-size: inherit;
}

.dlp-common .next-available a {
  color: black;
  text-decoration: none;
  font-size: inherit;
}

.dlp-desktop-tabs {
  text-decoration-thickness: 4px;
  text-underline-offset: 20px;
  text-decoration-color: #57bfce;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url('../assets/images/arrow-down-bold-black.svg');
  background-repeat: no-repeat;
  background-position-x: 95%;
  background-position-y: 15px;
  background-size: 12px 12px;
}

.country-drop-down {
  width: 100%;
  margin-top: 10px;
  background-position-y: 7px;
  padding-left: 16px;
  background-position-x: 97%;
  outline: none;
}

input[type='text'] {
  cursor: text;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
  display: none;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

img.lazyload,
img.lazyloading {
  opacity: 0 !important;
}

img.lazyload,
img.lazyloading,
img.lazyloaded {
  opacity: 1;
  transition: 1s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.swiper-lazy.swiper-lazy-loaded.carousel-images {
  opacity: 1;
  transition: 1s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.swiper-lazy.swiper-lazy-loading.carousel-images {
  opacity: 0 !important;
}

.hotel-card .swiper-lazy-preloader {
  border-color: #fefefe;
  border-top-color: transparent;
}

#__next {
  /* This is the id generated by nextjs for the root container. Setting some site level styles here */
  background-color: white;
  width: 100vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: visible;
}

.cursor-default:hover {
  cursor: default;
}

.fixed-dlp-banner {
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: white;
  width: 100%;
  border-top: 1px solid #ebebeb;
  z-index: 100;
}

.drawer select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url('../assets/images/arrow-down-bold-black.svg');
  background-repeat: no-repeat;
  background-position-x: 80%;
  background-position-y: 13px;
  background-size: 12px 12px;
  background-color: #fff;
}

.cookie-banner {
  z-index: 99999999 !important;
}

@media only screen and (min-width: 850px) {
  #__next {
    /* To remove horizontal scroll on page. Cannot set overflow-x to hidden because sticky property on header stops working */
    width: 99.99%;
  }
}

@media (max-width: 850px) {
  .seo-search {
    position: fixed;
    bottom: 15px;
    width: 100%;
    padding: 16px 24px;
    height: 95px;
    bottom: 0;
    background: #ffffff;
    box-shadow: 0px 4px 5px rgba(0, 116, 131, 0.14), 0px 1px 10px rgba(0, 116, 131, 0.12),
      0px 2px 4px rgba(0, 116, 131, 0.2);
    border-radius: 20px 20px 0px 0px;
    z-index: 9999;
  }
}

.react-datepicker__children-container {
  width: 100% !important;
}

.first-letter-uppercase::first-letter {
  text-transform: uppercase;
}

#booking-section .react-datepicker__input-container .mobile-smart-calendar::placeholder {
  font-family: 'proxima-nova-semi-bold';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #57bfce;
}

.no-select-highlight {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.no-select-highlight:focus {
  outline: none !important;
}

.align-baseline {
  align-items: baseline;
}

.snap-none {
  scroll-snap-type: none;
}

.snap-x {
  scroll-snap-type: x;
}

.snap-x.snap-mandatory {
  scroll-snap-type: x mandatory;
}

.snap-y {
  scroll-snap-type: y;
}

.snap-y.snap-mandatory {
  scroll-snap-type: y mandatory;
}

.snap-both {
  scroll-snap-type: both;
}

.snap-start {
  scroll-snap-align: start;
}

.snap-center {
  scroll-snap-align: center;
}

.snap-end {
  scroll-snap-align: end;
}

.snap-align-none {
  scroll-snap-align: none;
}
