.checkboxCommon input[type='checkbox'] {
  display: none;
}

.checkboxCommon input[type='checkbox'] + label:before {
  border: 1px solid #ebebeb;
  content: '';
  font: 16px/1em sans-serif;
  height: 24px;
  margin: -2px 0.75em 0 0;
  padding: 0;
  vertical-align: top;
  width: 24px;
  border-radius: 4px;
  position: absolute;
  right: 0px;
  cursor: pointer;
}

.checkboxCommon input[type='checkbox']:checked + label:before {
  border: none;
  background: #fff;
  content: '';
  text-align: center;
  vertical-align: middle;
  background-color: #57bfce;
  background-image: url('../../../assets/images/check-white.svg');
  background-repeat: no-repeat;
  background-size: 14px 14px;
  background-position-x: 5px;
  background-position-y: 4px;
}
.checkboxCommon input[type='checkbox']:checked + label:after {
  font-weight: bold;
}

.multiLine input[type='checkbox'] + label:before {
  border: 1px solid #ebebeb;
  content: '';
  font: 16px/1em sans-serif;
  height: 28px;
  margin: 7px 1em 0 0;
  padding: 0;
  vertical-align: top;
  width: 28px;
  border-radius: 4px;
  position: absolute;
  margin-left: -45px;
  background-color: #fff;
}

.multiLine input[type='checkbox']:checked + label:before {
  border: none;
  background: #fff;
  content: '';
  text-align: center;
  vertical-align: middle;
  background-color: #57bfce;
  background-image: url('../../../assets/images/check-white.svg');
  background-repeat: no-repeat;
  background-size: 15px 15px;
  background-position-x: 6px;
  background-position-y: 6px;
}

/* Dark Border */
.checkboxCommonDark input[type='checkbox'] {
  display: none;
}

.checkboxCommonDark input[type='checkbox'] + label:before {
  border: 1px solid #929292;
  content: '';
  font: 16px/1em sans-serif;
  height: 24px;
  margin: -2px 0.75em 0 0;
  padding: 0;
  vertical-align: top;
  width: 24px;
  border-radius: 4px;
  position: absolute;
  right: 0;
  cursor: pointer;
}

.checkboxCommonDark input[type='checkbox']:checked + label:before {
  border: none;
  background: #fff;
  content: '';
  text-align: center;
  vertical-align: middle;
  background-color: #57bfce;
  background-image: url('../../../assets/images/check-white.svg');
  background-repeat: no-repeat;
  background-size: 14px 14px;
  background-position-x: 5px;
  background-position-y: 4px;
}
.checkboxCommonDark input[type='checkbox']:checked + label:after {
  font-weight: bold;
}

/* Red Border */
.checkboxCommonRed input[type='checkbox'] {
  display: none;
}

.checkboxCommonRed input[type='checkbox'] + label:before {
  border: 1px solid #f14f4f;
  content: '';
  font: 16px/1em sans-serif;
  height: 24px;
  margin: -2px 0.75em 0 0;
  padding: 0;
  vertical-align: top;
  width: 24px;
  border-radius: 4px;
  position: absolute;
  margin-left: -36px;
}

.checkboxCommonRed input[type='checkbox']:checked + label:before {
  border: none;
  background: #fff;
  content: '';
  text-align: center;
  vertical-align: middle;
  background-color: #57bfce;
  background-image: url('../../../assets/images/check-white.svg');
  background-repeat: no-repeat;
  background-size: 14px 14px;
  background-position-x: 5px;
  background-position-y: 4px;
}
.checkboxCommonRed input[type='checkbox']:checked + label:after {
  font-weight: bold;
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .label {
    width: 85%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}

/** -------------------------------------------------------------------
 * Checkbox Common V2
 *---------------------------------------------------------------------
 * @description: This checkbox has been refactored for the use in the
 * search 2025 project.
 */

.checkboxCommonV2 input[type='checkbox'] {
  display: none;
}

.checkboxCommonV2 input[type='checkbox'] + label:before {
  border: 1px solid #ebebeb;
  content: '';
  font: 16px/1em sans-serif;
  height: 24px;
  margin-top: -2px;
  padding: 0;
  vertical-align: top;
  width: 24px;
  border-radius: 4px;
  position: absolute;
  right: 0px;
  cursor: pointer;
}

.checkboxCommonV2 input[type='checkbox']:checked + label:before {
  border: none;
  background: #fff;
  content: '';
  text-align: center;
  vertical-align: middle;
  background-color: #57bfce;
  background-image: url('../../../assets/images/check-white.svg');
  background-repeat: no-repeat;
  background-size: 14px 14px;
  background-position-x: 5px;
  background-position-y: 4px;
}
.checkboxCommonV2 input[type='checkbox']:checked + label:after {
  font-weight: bold;
}

.multiLine input[type='checkbox'] + label:before {
  border: 1px solid #ebebeb;
  content: '';
  font: 16px/1em sans-serif;
  height: 28px;
  margin: 7px 1em 0 0;
  padding: 0;
  vertical-align: top;
  width: 28px;
  border-radius: 4px;
  position: absolute;
  margin-left: -45px;
  background-color: #fff;
}

.multiLine input[type='checkbox']:checked + label:before {
  border: none;
  background: #fff;
  content: '';
  text-align: center;
  vertical-align: middle;
  background-color: #57bfce;
  background-image: url('../../../assets/images/check-white.svg');
  background-repeat: no-repeat;
  background-size: 15px 15px;
  background-position-x: 6px;
  background-position-y: 6px;
}

/* Dark Border */
.checkboxCommonDarkV2 input[type='checkbox'] {
  display: none;
}

.checkboxCommonDarkV2 input[type='checkbox'] + label:before {
  border: 1px solid #929292;
  content: '';
  font: 16px/1em sans-serif;
  height: 24px;
  margin-top: -2px;
  padding: 0;
  vertical-align: top;
  width: 24px;
  border-radius: 4px;
  position: absolute;
  right: 0;
  cursor: pointer;
}

.checkboxCommonDarkV2 input[type='checkbox']:checked + label:before {
  border: none;
  background: #fff;
  content: '';
  text-align: center;
  vertical-align: middle;
  background-color: #57bfce;
  background-image: url('../../../assets/images/check-white.svg');
  background-repeat: no-repeat;
  background-size: 14px 14px;
  background-position-x: 5px;
  background-position-y: 4px;
}
.checkboxCommonDarkV2 input[type='checkbox']:checked + label:after {
  font-weight: bold;
}

/* Red Border */
.checkboxCommonRedV2 input[type='checkbox'] {
  display: none;
}

.checkboxCommonRedV2 input[type='checkbox'] + label:before {
  border: 1px solid #f14f4f;
  content: '';
  font: 16px/1em sans-serif;
  height: 24px;
  margin-top: -2px;
  padding: 0;
  vertical-align: top;
  width: 24px;
  border-radius: 4px;
  position: absolute;
  margin-left: -36px;
}

.checkboxCommonRedV2 input[type='checkbox']:checked + label:before {
  border: none;
  background: #fff;
  content: '';
  text-align: center;
  vertical-align: middle;
  background-color: #57bfce;
  background-image: url('../../../assets/images/check-white.svg');
  background-repeat: no-repeat;
  background-size: 14px 14px;
  background-position-x: 5px;
  background-position-y: 4px;
}
.checkboxCommonRedV2 input[type='checkbox']:checked + label:after {
  font-weight: bold;
}
