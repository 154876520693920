.open {
  animation: move-up 0.3s;
  z-index: 10000;
  bottom: 0;
  max-height: 100%;
  /* touch-action: none; */
}

.openOffset {
  animation: move-up 0.3s;
  z-index: 10000;
  bottom: 0;
  max-height: 98%;
}

.close {
  animation: move-down 0.3s;
  z-index: 1000;
  height: 0;
  bottom: -100%;
}

@keyframes move-up {
  from {
    bottom: -100%;
  }
  to {
    bottom: 0; /* final resting position */
  }
}

@keyframes move-down {
  from {
    bottom: 0;
    height: auto;
  }
  to {
    bottom: -100%; /* final resting position */
    height: 0;
  }
}
