.underline-offset-9 {
  text-underline-offset: 9px;
}

.w-fit {
  width: fit-content;
}

.basis-6 {
  flex-basis: 6;
}
