.homepage-video::-webkit-media-controls-panel {
  display: none!important;
  -webkit-appearance: none;
}

.homepage-video::-webkit-media-controls-play-button {
  display: none!important;
  -webkit-appearance: none;
}

.homepage-video::-webkit-media-controls-start-playback-button {
  display: none!important;
  -webkit-appearance: none;
}

.homepage-video::-webkit-media-controls {
  display:none !important;
}
