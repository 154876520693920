.popup {
  animation: shake 0.3s;
  z-index: 10000000 !important;
}

.popupBackground {
  z-index: 1000000 !important;
}

@keyframes shake {
  0% {
    transform: scale(0.7);
  }
  45% {
    transform: scale(1.05);
  }
  80% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(1);
  }
}
