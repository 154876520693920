.react-datepicker__input-container > input:focus {
  outline: none !important;
}

.react-datepicker__input-container > input {
  width: 100%;
  touch-action: manipulation !important;
  margin-top: 0;
}

.react-datepicker__header {
  background: white !important;
  border: none !important;
}

.react-datepicker {
  font-family: 'proxima-nova-regular' !important;
  border: none !important;
  box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.12);
  border-radius: 12px !important;
}

.desktop .react-datepicker {
  max-width: 400px;
}

.desktop .react-datepicker-popper {
  margin-bottom: -25px;
  margin-left: -37px;
  margin-top: 5px;
}

.desktop-fixed .react-datepicker {
  max-width: 400px;
}

.desktop-fixed .react-datepicker-popper {
  margin-bottom: -25px;
  margin-left: -17px;
  margin-top: 5px;
}

.options::-webkit-scrollbar {
  display: none;
}

.react-datepicker__triangle {
  background-color: white !important;
  display: none !important;
}

.react-datepicker__month {
  padding: 0 15px;
}

.react-datepicker__month {
  @apply font-rp-pn-regular d:font-rp-pn-regular d:font-semibold;
}

.react-datepicker__week {
  font-size: 14px;
  margin-bottom: 0px;
}

.react-datepicker__day-names {
  font-size: 13px;
  opacity: 0.5;
  margin-left: 0.4rem;
  margin-right: 0.4rem;
}

.react-datepicker__day-name {
  margin: 0.5rem !important;
  width: 2rem !important;
  line-height: 2rem !important;
  margin-bottom: 5px !important;
}

.react-datepicker__day--selected {
  background-color: #3d3d3d !important;
  border-radius: 100% !important;
}

.react-datepicker__day--disabled {
  opacity: 0.3 !important;
}

.react-datepicker__day--selected:focus {
  outline: 0 !important;
}

.react-datepicker__day--keyboard-selected {
  background-color: white !important;
  color: black !important;
  border: none !important;
  outline: 0 !important;
}

.react-datepicker__day {
  margin: 0.5rem !important;
  width: 2rem !important;
  line-height: 2rem !important;
  position: relative;
}

.react-datepicker__day:hover {
  border-radius: 100% !important;
  width: 2rem !important;
  line-height: 2rem !important;
}

.react-datepicker__month-container {
  border-bottom: 1px solid #ebebeb;
  padding-bottom: 10px;
}

.react-datepicker__day--outside-month {
  opacity: 0.3 !important;
  pointer-events: none;
  color: #ccc !important;
}

.react-datepicker__day--selected.react-datepicker__day--outside-month {
  background-color: white !important;
  color: #ccc !important;
}

/* mobile styles  */
.mobile .react-datepicker {
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.mobile.open .react-datepicker-popper {
  transform: none !important;
  z-index: 1000000 !important;
  width: 100%;
  animation: move-up 0.5s;
  position: fixed !important;
  overflow-y: scroll !important;
  height: 100% !important;
}

.mobile.close .react-datepicker-popper {
  transform: none !important;
  z-index: 1000000 !important;
  width: 100%;
  animation: move-down 0.5s;
}

.mobile.close .react-datepicker__month-container {
  display: none;
  animation: move-down 0.5s;
}

.mobile .first-header {
  margin-top: 40%;
}

.mobile.open .fixed-header {
  animation: move-up 0.5s;
  border-bottom: 1px solid #ebebeb;
}

.mobile.close .fixed-header {
  animation: move-down 0.5s;
  height: 1200px;
}

@keyframes move-up {
  from {
    top: 100%;
  }
  to {
    top: 0; /* final resting position */
  }
}

@keyframes move-down {
  from {
    top: 0;
  }
  to {
    top: 100%; /* final resting position */
  }
}

.mobile .date-footer {
  position: fixed;
  bottom: 0;
  background-color: white;
  width: 100%;
  border-top: 1px solid #ebebeb;
}

.mobile .react-datepicker__month-container {
  border-bottom: none;
  font-weight: 600;
}

.mobile .react-datepicker__current-month {
  text-align: left;
  font-size: 22px;
  padding-left: 25px;
  margin-bottom: 10px;
}

.mobile .option-selected {
  border: 1px solid #252525;
  background-color: #f7f7f7;
  pointer-events: none;
}

.mobile .react-datepicker__day {
  margin: 0.5rem !important;
}

.mobile .react-datepicker__week {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0.5rem auto !important;
}

.mobile .react-datepicker__day-names {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 25px;
  margin-top: 1rem;
}

.cross-dates {
  color: #969696 !important;
  pointer-events: none;
  font-family: 'proxima-nova-light';
}

.cross-dates::after {
  content: '';
  width: 1px;
  height: 60%;
  background-color: #969696;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0) rotate(35deg);
}

.cross-dates.react-datepicker__day--outside-month::after {
  display: none;
}

/* Spinner */
.smart-calendar-spinner-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
  pointer-events: all;
  opacity: 1;
  z-index: 10;
}

.smart-calendar-spinner {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.smart-calendar-spinner div {
  position: absolute;
  width: 6px;
  height: 6px;
  background: #555;
  border-radius: 50%;
  animation: smart-calendar-spinner 1.2s linear infinite;
}
.smart-calendar-spinner div:nth-child(1) {
  animation-delay: 0s;
  top: 37px;
  left: 66px;
}
.smart-calendar-spinner div:nth-child(2) {
  animation-delay: -0.1s;
  top: 22px;
  left: 62px;
}
.smart-calendar-spinner div:nth-child(3) {
  animation-delay: -0.2s;
  top: 11px;
  left: 52px;
}
.smart-calendar-spinner div:nth-child(4) {
  animation-delay: -0.3s;
  top: 7px;
  left: 37px;
}
.smart-calendar-spinner div:nth-child(5) {
  animation-delay: -0.4s;
  top: 11px;
  left: 22px;
}
.smart-calendar-spinner div:nth-child(6) {
  animation-delay: -0.5s;
  top: 22px;
  left: 11px;
}
.smart-calendar-spinner div:nth-child(7) {
  animation-delay: -0.6s;
  top: 37px;
  left: 7px;
}
.smart-calendar-spinner div:nth-child(8) {
  animation-delay: -0.7s;
  top: 52px;
  left: 11px;
}
.smart-calendar-spinner div:nth-child(9) {
  animation-delay: -0.8s;
  top: 62px;
  left: 22px;
}
.smart-calendar-spinner div:nth-child(10) {
  animation-delay: -0.9s;
  top: 66px;
  left: 37px;
}
.smart-calendar-spinner div:nth-child(11) {
  animation-delay: -1s;
  top: 62px;
  left: 52px;
}
.smart-calendar-spinner div:nth-child(12) {
  animation-delay: -1.1s;
  top: 52px;
  left: 62px;
}
@keyframes smart-calendar-spinner {
  0%,
  20%,
  80%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
}
