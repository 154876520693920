.react-datepicker__input-container > input {
  @apply focus:outline-none mt-1.5;
}

.react-datepicker__input-container > input::placeholder {
  color: grey;
}

.home-page-date-picker {
  width: 80%;
}

.home-page-date-picker .react-datepicker {
  @apply mt-0 pt-4 d:-mt-1;
}

.home-page-date-picker .react-datepicker__navigation {
  @apply mt-7;
}

.home-page-date-picker .react-datepicker__day-names {
  @apply pt-3;
}

.home-page-date-picker .react-datepicker-popper {
  @apply -ml-2 d:-ml-16;
}

.home-page-date-picker .react-datepicker__input-container > input {
  @apply focus:outline-none;
}

.home-page-date-picker .react-datepicker__input-container > input {
  caret-color: transparent !important;
  margin-top: 0px;
}

.home-page-date-picker .react-datepicker {
  transform: scale(0.95);
}

/* Redesigned date picker */
.redesign-date-picker {
  width: 80%;
  font-family: basesitca;
}

.redesign-date-picker .react-datepicker {
  @apply mt-0 pt-4 d:-mt-1;
}

.redesign-date-picker .react-datepicker__navigation {
  @apply mt-7;
}

.redesign-date-picker .react-datepicker__day-names {
  @apply pt-3;
}

.redesign-date-picker .react-datepicker-popper {
  transform: none !important;
  left: -10px !important;
  top: 55% !important;
  margin-left: 0;
  @media (min-width: 850px) {
    margin-left: -48px !important;
    transform: inherit;
    left: auto !important;
    top: auto !important;
  }
}

.redesign-date-picker .react-datepicker__input-container > input {
  @apply focus:outline-none;
}

.redesign-date-picker .react-datepicker__input-container > input {
  caret-color: transparent !important;
  margin-top: 0px;
}

.redesign-date-picker .react-datepicker {
  transform: scale(0.95);
  border-radius: 4px !important;
}


.srp-date-picker .react-datepicker__input-container > input {
  @apply mt-1.5;
}

.react-datepicker__children-container {
  width: 100% !important;
}

#scroll-helper {
  position: fixed;
  bottom: 0;
  background-color: transparent;
  width: 100%;
}

#gift-card-purchase .react-datepicker-popper {
  z-index: 999 !important;
}
