.gallery.open,
.carousel.open {
  animation: move-up 0.5s;
  top: 0;
}

.carousel.close {
  animation: move-down 0.5s;
  top: 100%;
}

.gallery.close {
  animation: move-down 0.5s;
  position: fixed;
  top: 100%;
}

@keyframes move-up {
  from {
    top: 100%;
  }
  to {
    top: 0; /* final resting position */
  }
}

@keyframes move-down {
  from {
    top: 0;
  }
  to {
    top: 100%; /* final resting position */
  }
}
