.react-dropdown-component {
  @apply z-20 bg-white text-left border-opacity-0 rounded-t-md d:rounded-md d:border-r d:rounded-r-none d:border-b-0;
}

.react-dropdown-component .react-dropdown-container {
  @apply d:mt-px h-full;
}

.react-dropdown-component .react-dropdown-options__control--is-focused {
  @apply shadow-none;
}

.react-dropdown-component .react-dropdown-options__control {
  @apply flex items-center justify-start cursor-pointer border-rp-gray-divider h-full hover:border-rp-gray-divider focus:border-rp-gray-divider;
}

.react-dropdown-component .react-dropdown-options__placeholder {
  @apply text-sm font-rp-pn-regular absolute -mt-px text-rp-primary-black d:font-semibold;
}

.react-dropdown-component
  .react-dropdown-options__control--menu-is-open
  .react-dropdown-options__placeholder {
  @apply text-sm font-rp-pn-regular absolute -mt-px text-rp-primary-dark d:font-semibold;
}

.react-dropdown-component .react-dropdown-options__control--menu-is-open {
  @apply bg-rp-primary-10;
}

.react-dropdown-component .react-dropdown-options__single-value {
  @apply font-rp-pn-regular text-sm d:font-semibold;
}

.react-dropdown-component
  .react-dropdown-options__control--menu-is-open
  .react-dropdown-options__value-container--has-value
  .react-dropdown-options__single-value {
  @apply text-rp-primary-dark font-rp-pn-regular d:font-semibold;
}

.react-dropdown-options__menu-portal .react-dropdown-options__option {
  @apply px-3 font-rp-pn-regular text-sm cursor-pointer d:font-semibold;
}

.react-dropdown-options__menu-list .react-dropdown-options__option--is-selected {
  @apply bg-rp-primary-light;
}

.react-dropdown-options__menu-portal .react-dropdown-options__menu {
  @apply mt-px shadow-drop-down;
}

.react-dropdown-options__indicator-separator {
  @apply hidden;
}

.react-dropdown-options__menu-list .react-dropdown-options__option--is-selected {
  @apply bg-transparent text-black;
}

.react-dropdown-options__menu-list .react-dropdown-options__option:hover {
  @apply bg-transparent;
}

.react-dropdown-options__menu-list .react-dropdown-options__option--is-focused {
  @apply bg-transparent;
}

.react-dropdown-options__menu-list
  .react-dropdown-options__option.react-dropdown-options__option--is-selected::before {
  text-align: left;
  content: '';
  background-image: url('../../../assets/images/check.svg');
  background-repeat: no-repeat;
  padding-left: 13px;
  padding-right: 18px;
  margin-left: -30px;
}

.react-dropdown-options__menu-list .react-dropdown-options__option {
  content: '';
  padding-left: 43px;
  padding-top: 10px;
}

.react-dropdown-options__menu-list
  .react-dropdown-options__option.react-dropdown-options__option--is-selected {
  padding-left: 43px;
}

.react-dropdown-options__menu-list {
  padding-top: 8px !important;
  padding-bottom: 13px !important;
  border: none !important;
}
