.react-select-component {
  @apply z-20 bg-white rounded-t-md border-b border-solid border-rp-gray-divider w-full d:rounded-md d:border-r d:rounded-r-none d:border-b-0 d:w-auto;
}

.react-select-component .react-select-container {
  @apply h-16 my-0 mx-auto border-transparent focus:border-opacity-0 d:w-96 d:mt-px;
}

.react-select-component .react-select-container-header {
  @apply h-50 my-0 mx-auto;
}

.react-select-component .react-select-options__control {
  @apply h-16 border-transparent flex items-center justify-start cursor-text hover:border-transparent focus:border-transparent;
}

.react-select-component .react-select-options__control--is-focused {
  @apply border-transparent shadow-none;
}

.react-select-component .react-select-options__indicators {
  @apply hidden;
}

.react-select-component .react-select-options__placeholder {
  @apply text-lg font-rp-pn-regular absolute -mt-px d:text-lg;
}

.react-select-component .react-select-options__input-container {
  @apply text-lg -mt-1 font-rp-pn-regular w-64 overflow-hidden d:text-lg d:w-90;
}

.react-select-component .react-select-options__input-container > input {
  @apply static;
}

.react-select-component .react-select-options__value-container {
  @apply h-full;
}

.react-select-component .react-select-options__single-value {
  @apply text-lg absolute -mt-px font-rp-pn-regular d:text-lg;
}

/* menu styles */

.react-select-options__menu-portal .react-select-options__menu {
  @apply rounded-md max-h-80 overflow-y-scroll mt-px bg-white shadow-search w-full -ml-14 d:mt-4 d:w-896 d:-ml-11;
}

.react-select-options__menu-portal .react-select-options__menu-list {
  @apply bg-white pb-0;
}

.react-select-options__menu-portal .react-select-options__option {
  @apply bg-white pt-5 pl-6 pr-8 h-16 font-rp-pn-regular text-lg text-rp-gray cursor-pointer hover:bg-rp-gray-divider bg-location-icon bg-cover d:text-lg;
}

.react-select-options__menu-portal .zip {
  @apply pb-16 tracking-tighter cursor-auto pointer-events-none d:tracking-normal d:pb-8;
}

.react-select-options__menu-portal .react-select-options__option.no-icon {
  @apply bg-white pt-2.5 pb-1.5 pl-6 pr-8 h-12 font-rp-pn-regular text-lg text-rp-gray cursor-pointer hover:bg-rp-gray-divider bg-location-icon bg-cover d:text-lg;
}

.react-select-options__menu-portal .react-select-options__option.current-location {
  @apply bg-white pt-2.5 pb-1.5 pl-6 pr-8 h-12 font-rp-pn-regular text-lg text-rp-gray cursor-pointer border-b border-rp-gray-divider hover:bg-rp-gray-divider bg-location-icon bg-cover d:text-lg;
}

.react-select-options__menu-portal .frozen {
  @apply h-13 pt-4 tracking-tighter cursor-auto pointer-events-none d:tracking-normal;
}

.react-select-options__menu-portal .frozen .faded {
  @apply opacity-70;
}

/* ******** */
/* ******** */
/* ******** */
/* ******** */
/* React select for SRP */
.react-select-srp {
  @apply box-content h-11 flex-1 text-rp-primary border-0 w-11/12 overflow-hidden overflow-ellipsis d:w-full;
}

.react-select-srp .react-select-container {
  @apply h-16 my-0 mx-auto border-transparent focus:border-opacity-0 d:w-96 d:mt-px;
}

.react-select-srp .react-select-container-header {
  @apply h-50 my-0 mx-auto;
}

.react-select-srp .react-select-options-srp__control {
  @apply h-16 border-transparent flex items-center justify-start cursor-text flex-nowrap hover:border-transparent focus:border-transparent;
}

.react-select-srp .react-select-options-srp__control--is-focused {
  @apply border-transparent shadow-none d:ml-0;
}

.react-select-srp .react-select-options-srp__indicators {
  @apply hidden;
}

.react-select-srp .react-select-options-srp__placeholder {
  @apply text-base font-rp-pn-regular absolute mt-0.5 opacity-50 d:text-lg;
}

.react-select-srp .react-select-options-srp__input-container {
  @apply text-base -mt-1 font-rp-pn-regular min-w-102 d:text-lg d:min-w-190;
}

.react-select-srp .react-select-options-srp__value-container {
  @apply h-full;
}

.react-select-srp .react-select-options-srp__input-container > input {
  @apply static;
}

.react-select-srp .react-select-options-srp__single-value {
  @apply text-base absolute -mt-px font-rp-pn-regular d:text-lg;
}

.react-select-options-srp__input {
  width: 120% !important;
}

.react-select-srp .react-select-options-srp__input-container {
  margin: 0 !important;
}

.react-select-options__input {
  width: 100% !important;
  position: absolute !important;
}

/* menu styles for srp */

.react-select-options-srp__menu-portal {
  width: 90% !important;
  margin-left: -45px;
}

.react-select-options-srp__menu-portal .react-select-options-srp__menu {
  @apply rounded-md max-h-80 overflow-y-scroll mt-px bg-white shadow-search w-full d:mt-1 d:w-454;
}

.react-select-options-srp__menu-portal .react-select-options-srp__menu-list {
  @apply bg-white pb-0;
}

.react-select-options-srp__menu-portal .react-select-options-srp__option {
  @apply bg-white pt-2 pb-2 pl-4 pr-8 font-rp-pn-regular text-15 text-rp-gray cursor-pointer hover:bg-rp-gray-divider bg-location-icon bg-cover d:text-lg;
}

.react-select-options-srp__menu-portal .zip {
  @apply pb-3 tracking-tighter cursor-auto pointer-events-none d:pb-3 d:tracking-normal;
}

@media (max-width: 850px) {
  .react-select-options__menu-portal .react-select-options__menu {
    width: 118% !important;
  }

  .react-select-options-srp__menu-portal {
    width: 90% !important;
    margin-left: -33px;
    left: 54px !important;
  }

  .react-select-options-srp-sticky__menu-portal {
    width: 90% !important;
    margin-left: -33px;
    left: 54px !important;
  }

  .react-select-options-bottom__menu-portal .react-select-options-bottom__menu {
    width: 120% !important;
  }

  .react-select-options-srp-sticky__input {
    width: 130px !important;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}

/* ******** */
/* ******** */
/* ******** */
/* ******** */
/* React select for bottom of home page */

.react-select-component .react-select-options-bottom__control {
  @apply h-16 border-transparent flex items-center justify-start cursor-text hover:border-transparent focus:border-transparent;
}

.react-select-component .react-select-options-bottom__control--is-focused {
  @apply border-transparent shadow-none;
}

.react-select-component .react-select-options-bottom__indicators {
  @apply hidden;
}

.react-select-component .react-select-options-bottom__placeholder {
  @apply text-lg font-rp-pn-regular absolute -mt-px d:text-lg;
}

.react-select-component .react-select-options-bottom__input-container {
  @apply text-lg -mt-1 font-rp-pn-regular w-64 overflow-hidden d:text-lg d:w-90;
}

.react-select-component .react-select-options-bottom__input-container > input {
  @apply static;
}

.react-select-component .react-select-options-bottom__value-container {
  @apply h-full;
}

.react-select-component .react-select-options-bottom__single-value {
  @apply text-lg absolute -mt-px font-rp-pn-regular d:text-lg;
}

.react-select-options-bottom__menu-portal .react-select-options-bottom__menu {
  @apply rounded-md max-h-80 overflow-y-scroll mt-px bg-white shadow-search w-full -ml-14 d:mt-4 d:w-520 d:-ml-12;
}

.react-select-options-bottom__menu-portal .react-select-options-bottom__menu-list {
  @apply bg-white pb-0;
}

.react-select-options-bottom__menu-portal .react-select-options-bottom__option {
  @apply bg-white pt-5 pl-6 pr-8 h-16 font-rp-pn-regular text-lg text-rp-gray cursor-pointer hover:bg-rp-gray-divider bg-location-icon bg-cover d:text-lg;
}

.react-select-options-bottom__menu-portal .zip {
  @apply pb-16 tracking-tighter cursor-auto pointer-events-none d:tracking-normal d:pb-16;
}

.react-select-options-bottom__input {
  width: 120% !important;
}

/* sticky search */
.react-select-srp-sticky {
  @apply box-content h-11 flex-1 text-rp-primary border-0 w-11/12 overflow-hidden overflow-ellipsis d:w-full;
}

.react-select-srp-sticky .react-select-container {
  @apply h-16 my-0 mx-auto border-transparent focus:border-opacity-0 d:w-96 d:mt-px;
}

.react-select-srp-sticky .react-select-container-header {
  @apply h-50 my-0 mx-auto;
}

.react-select-srp-sticky .react-select-options-srp-sticky__control {
  @apply h-16 border-transparent flex items-center justify-start cursor-text flex-nowrap hover:border-transparent focus:border-transparent;
}

.react-select-srp-sticky .react-select-options-srp-sticky__control--is-focused {
  @apply border-transparent shadow-none d:ml-0;
}

.react-select-srp-sticky .react-select-options-srp-sticky__indicators {
  @apply hidden;
}

.react-select-srp-sticky .react-select-options-srp-sticky__placeholder {
  @apply text-base font-rp-pn-regular absolute mt-0.5 opacity-50 d:text-lg;
}

.react-select-srp-sticky .react-select-options-srp-sticky__input-container {
  @apply text-base -mt-1 font-rp-pn-regular min-w-102 d:text-lg d:min-w-190;
}

.react-select-srp-sticky .react-select-options-srp-sticky__value-container {
  @apply h-full;
}

.react-select-srp-sticky .react-select-options-srp-sticky__input-container > input {
  @apply static;
}

.react-select-srp-sticky .react-select-options-srp-sticky__single-value {
  @apply text-base absolute -mt-px font-rp-pn-regular d:text-lg;
}

.react-select-srp-sticky .react-select-options-srp-sticky__input-container {
  margin: 0 !important;
}

/* menu styles for sticky */

.react-select-options-srp-sticky__menu-portal {
  width: 90% !important;
  margin-left: -45px;
}

.react-select-options-srp-sticky__menu-portal .react-select-options-srp-sticky__menu {
  @apply rounded-md max-h-80 overflow-y-auto mt-4 bg-white shadow-search w-full ml-2 d:mt-1 d:w-454;
}

.react-select-options-srp-sticky__menu-portal .react-select-options-srp-sticky__menu-list {
  @apply bg-white pb-0;
}

.react-select-options-srp-sticky__menu-portal .react-select-options-srp-sticky__option {
  @apply bg-white pt-2 pb-2 pl-4 pr-8 font-rp-pn-regular text-15 text-rp-gray cursor-pointer hover:bg-rp-gray-divider bg-location-icon bg-cover d:text-lg;
}

.react-select-options-srp-sticky__menu-portal .zip {
  @apply pb-3 tracking-tighter cursor-auto pointer-events-none d:pb-3 d:tracking-normal;
}

/* New Home Page Desktop */

.react-select-component-home {
  @apply z-20 bg-white rounded-t-md border-b border-solid border-rp-gray-divider w-full d:rounded-md d:border-r d:rounded-r-none d:border-b-0 d:w-auto;
}

.react-select-component-home .react-select-container {
  @apply h-16 my-0 mx-auto border-transparent focus:border-opacity-0 d:w-454 d:mt-px;
}

.react-select-component-home .react-select-container-header {
  @apply h-50 my-0 mx-auto;
}

.react-select-component-home .react-select-options-home__control {
  @apply h-16 border-transparent flex items-center justify-start cursor-text hover:border-transparent focus:border-transparent;
}

.react-select-component-home .react-select-options-home__control--is-focused {
  @apply border-transparent shadow-none;
}

.react-select-component-home .react-select-options-home__indicators {
  @apply hidden;
}

.react-select-component-home .react-select-options-home__placeholder {
  @apply text-lg font-rp-pn-regular absolute -mt-px d:text-lg;
}

.react-select-component-home .react-select-options-home__input-container {
  @apply text-lg -mt-1 font-rp-pn-regular w-64 overflow-hidden d:text-lg d:w-90;
}

.react-select-component-home .react-select-options-home__input-container > input {
  @apply static;
}

.react-select-component-home .react-select-options-home__value-container {
  @apply h-full;
}

.react-select-component-home .react-select-options-home__single-value {
  @apply text-lg absolute -mt-px font-rp-pn-regular d:text-lg;
}

/* Redesigned searchbar */

.redesigned-searchbar {
  @apply flex items-center z-20 w-full;
}

.redesigned-searchbar .react-select-container {
  @apply h-11 border-transparent focus:border-opacity-0 w-full;
}

.redesigned-searchbar .react-select-container-header {
  @apply h-11 my-0 mx-auto;
}

.redesigned-searchbar .react-select-options-home__control {
  @apply h-11 border-transparent flex items-center justify-start cursor-text hover:border-transparent focus:border-transparent;
}

.redesigned-searchbar .react-select-options-home__control--is-focused {
  @apply border-transparent shadow-none;
}

.redesigned-searchbar .react-select-options-home__indicators {
  @apply hidden;
}

.redesigned-searchbar .react-select-options-home__placeholder {
  @apply text-base font-rp-basetica w-full absolute;
}

.redesigned-searchbar .react-select-options-home__input-container {
  @apply text-lg flex font-rp-pn-regular w-full overflow-hidden d:text-lg;
}

.redesigned-searchbar .react-select-options-home__input-container::after {
  content: none;
}

.redesigned-searchbar .react-select-options-home__input-container > input {
  @apply static;
}

.redesigned-searchbar .react-select-options-home__value-container {
  @apply h-full;
}

.redesigned-searchbar .react-select-options-home__single-value {
  @apply text-lg absolute -mt-px font-rp-pn-regular d:text-lg;
}

.react-select-options-home__menu-portal .react-select-options-home__menu:has(> .menu-redesign) {
  @apply rounded max-h-96 overflow-y-auto mt-4 bg-white left-8 shadow-search w-343 max-w-343 d:mt-6 d:left-2.5;
}

.menu-redesign .react-select-options-home__option {
  padding: 16px !important;
  height: auto !important;
  min-height: auto !important;
}

/* menu styles */

.react-select-options-home__menu-portal .react-select-options-home__menu {
  @apply rounded-20 max-h-96 overflow-y-auto mt-4 bg-white shadow-search w-full -ml-14 d:mt-6 d:w-520 d:-ml-14;
}

.react-select-options-home__menu-portal .react-select-options-home__menu-list {
  @apply bg-white pb-0;
}

.react-select-options-home__menu-portal .react-select-options-home__option {
  @apply bg-white pt-5 pl-6 pr-8 min-h-65 font-rp-pn-regular text-lg text-rp-gray cursor-pointer hover:bg-rp-gray-divider bg-location-icon bg-cover d:text-lg;
}

.react-select-options-home__menu-portal .zip {
  @apply pb-16 tracking-tighter cursor-auto pointer-events-none d:tracking-normal d:pb-8;
}

.react-select-options-home__menu-portal .react-select-options-home__option.no-icon {
  @apply bg-white pt-2.5 pb-1.5 pl-6 pr-8 h-12 font-rp-pn-regular text-lg text-rp-gray cursor-pointer hover:bg-rp-gray-divider bg-location-icon bg-cover d:text-lg;
}

.react-select-options-home__menu-portal .react-select-options-home__option.current-location {
  @apply bg-white pt-2.5 pb-1.5 pl-6 pr-8 h-12 font-rp-pn-regular text-lg text-rp-gray cursor-pointer border-b border-rp-gray-divider hover:bg-rp-gray-divider bg-location-icon bg-cover d:text-lg;
}

.react-select-options-home__menu-portal .frozen {
  @apply h-13 pt-4 tracking-tighter cursor-auto pointer-events-none d:tracking-normal;
}

.react-select-options-home__menu-portal .frozen .faded {
  @apply opacity-70;
}
