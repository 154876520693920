.mobile-variant-date-picker .react-datepicker {
  box-shadow: none;
  border-radius: 0 !important;
  margin-top: 16px;
}

.mobile-variant-date-picker .react-datepicker__navigation {
  display: none !important;
}

.mobile-variant-date-picker .react-datepicker__month-container {
  border-bottom: none;
  padding-bottom: 0;
  width: 100%;
  margin-bottom: 32px;
}

.mobile-variant-date-picker .react-datepicker__month {
  margin: 0 8px;
  padding: 0;
}

.mobile-variant-date-picker .react-datepicker__day--selected {
  background-color: #252525 !important;
  color: #ffffff !important;
  border-radius: 6px !important;
}

.mobile-variant-date-picker .react-datepicker__day--outside-month,
.mobile-variant-date-picker .react-datepicker__day--disabled {
  opacity: 1 !important;
  color: #b0b0b0 !important;
  background: #ffffff !important;
}

.mobile-variant-date-picker .react-datepicker__week {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: space-between;
}

.mobile-variant-date-picker .react-datepicker__day {
  color: #252525;
  font-family: 'proxima-nova-regular';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal !important;
  margin: 4px !important;
  width: 40px !important;
  height: 40px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mobile-variant-date-picker .react-datepicker__current-month {
  font-family: 'proxima-nova-regular' !important;
  font-size: 22px !important;
  font-weight: 600 !important;
  line-height: 35px;
}

.mobile-variant-date-picker .react-datepicker__header {
  padding: 0px !important;
  text-align: left !important;
  border-radius: 0 !important;
}

.mobile-variant-date-picker .react-datepicker__day-names {
  margin: 0 8px !important;
  opacity: 1;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.mobile-variant-date-picker .react-datepicker__day-name {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-family: 'proxima-nova-regular';
  font-size: 14px;
  color: #6e8387;
  font-weight: 600;
  line-height: normal !important;
  width: 40px !important;
  height: 40px !important;
  margin: 0 !important;
}

.mobile-variant-date-picker .limited-quantity {
  border-radius: 6px;
  background-color: #fbeccd;
  color: #252525;
}

/* .mobile-variant-date-picker .available {
  border-radius: 6px;
  background: #cdecf0;
  color: #252525;
} */

.smart-calendar-input::placeholder {
  color: #57bfce;
}
