.wrapper {
  background-color: white;
  width: 400px;
  padding: 2rem;
  box-shadow: 0 1.5rem 1rem -1rem rgba(0, 0, 0, 0.1);
  border-radius: 0.3rem;
}

.input {
  width: 100%;
  height: 45px;
  padding: 0 1rem;
  box-sizing: border-box;
  font: inherit;
  border-radius: 12px;
  border: 2px solid #57bfce;
  color: #565656;
  -webkit-appearance: none;
  text-decoration: none !important;
  outline: none !important;
}

.input:focus {
  border-color: cornflowerblue;
  outline: none;
}

.input.has-error {
  border-color: #f14f4f;
}

.error {
  margin-top: 5px;
  margin-left: 5px;
  font-size: 90%;
  color: #f14f4f;
  font-weight: bold;
}

.tag-item {
  display: inline-block;
  font-weight: 400;
  font-size: 17px;
  line-height: 24px;
  border-radius: 8px;
  height: 32px;
  padding: 0 4px 0 1rem;
  display: inline-flex;
  align-items: center;
  margin-top: 10px;
  margin-left: 5px;
  margin-bottom: 2px;
  border: 1px solid #EBEBEB;
  background: #F6F8F9;
}

.tag-item > .button {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  font: inherit;
  margin-left: 10px;
  font-weight: bold;
  padding: 0;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
