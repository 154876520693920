@media only screen and (min-width: 1370px) and (max-width: 1440px) {
  .collapsibleContainer {
    width: 250px;
  }
}

@media only screen and (min-width: 1440px) {
  .collapsibleContainer {
    width: 310px;
  }
}

@media only screen and (min-width: 1280px) and (max-width: 1370px) {
  .collapsibleContainer {
    width: 220px;
  }
}
