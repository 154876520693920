.heroImage {
  width: 100vw;
  height: 100vw;
}

@media (min-width: 850px) {
  .heroImage {
    width: 100%;
    height: calc(min(100vw, 1920px) * 169 / 360);
    max-height: max(600px, 100vh - 120px);
  }
}
